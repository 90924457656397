import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";

const Content = (props) => {
    return (
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-red-50 flex flex-col text-[14px] font-medium">
            <Navbar />
            <div className="flex items-stretch grow">
                {props.sidebar !== false && (<Sidebar />)}
                <div className="bg-slate-100 grow relative">
                    <div className={`absolute top-0 left-0 right-0 bottom-0 px-5 py-7 overflow-auto ${props.sidebar === false && 'flex justify-center'}`}>
                        <div className={`${props.sidebar === false && 'w-full md:w-[95%] xl:w-[80rem]'} min-w-[50rem]`}>
                            {props.children}
                            <div className="h-10" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;