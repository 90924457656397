import { Content } from "../../components";

const OverviewPage = () => {
    return (
        <Content>
            <div>
                <h1 className="text-xl font-bold">Hi Provider</h1>
                <span>Selamat datang dan jadilah provider terpercaya</span>
            </div>
            <div className="mt-4">
                <div className="flex gap-3">
                    <div className="bg-slate-400 rounded-lg w-[23rem] h-[8rem]"></div>
                    <div className="bg-slate-400 rounded-lg w-[23rem] h-[8rem]"></div>
                </div>
            </div>
            <div className="mt-3">
                <div className="flex gap-3">
                    <div className="bg-slate-400 rounded-lg h-[20rem] grow"></div>
                    <div className="bg-slate-400 rounded-lg h-[20rem] grow"></div>
                </div>
            </div>
        </Content>
    );
}

export default OverviewPage;