import { useEffect, useRef, useState } from "react";
import { showServiceCategoryRequest } from "../../../requests";
import { getLocalToken } from "../../../utils";
import { Button } from "../../atoms";
import Card from "../card/Card";

const CategoryServiceModal = (props) => {
    const token = getLocalToken();
    const ref = useRef();
    const [isShow, setIsShow] = useState(false);
    const [category, setCategory] = useState([]);

    const getCategoryService = async () => {
        await showServiceCategoryRequest({ token: token.token }).then((res) => {
            setCategory(res.data.category);
        });
    }

    const toogleModal = () => {
        getCategoryService();
        setIsShow(!isShow);
    }

    const handleClickOutside = (event) => {
        if (!ref?.current?.contains(event.target)) {
            setIsShow(false);
        }
    };

    const onAddCategory = (index, cIndex) => {
        let chooseCategory = category[index].child_encode[cIndex].title;
        props.callback && props.callback(chooseCategory);
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div className="relative inline-block" ref={ref}>
            <div className="flex">
                <Button className="border" onClick={() => toogleModal()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 5l0 14"></path>
                        <path d="M5 12l14 0"></path>
                    </svg>
                    Tambah
                </Button>
            </div>
            <div className={`absolute top-9 ${!isShow && 'hidden'}`}>
                <Card className="w-64 max-h-40 overflow-y-auto">
                    {category.map((item, index) => {
                        return (
                            <div key={index} className="pb-3 mb-2 border-b">
                                <span className="font-bold">{item.category}</span>
                                <div className="flex flex-wrap gap-1 mt-2">
                                    {item.child_encode.map((cItem, cIndex) => {
                                        return (
                                            <div key={cIndex} className="rounded-full border border-slate-200 px-3 py-1 cursor-pointer hover:bg-slate-50 leading-4" onClick={() => onAddCategory(index, cIndex)}>{cItem.title}</div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </Card>
            </div>
        </div>
    );
}

export default CategoryServiceModal;