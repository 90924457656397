export const getLocalToken = () => {
    return JSON.parse(localStorage.getItem('pusproject.token'));
}

export const setLocalToken = (value) => {
    localStorage.setItem('pusproject.token', JSON.stringify(value));
}

export const setLocalUser = (value) => {
    localStorage.setItem('pusproject.user', JSON.stringify(value));
}

export const getLocalUser = () => {
    return JSON.parse(localStorage.getItem('pusproject.user'));
}

export const setLocalProvider = (value) => {
    localStorage.setItem('pusproject.provider', JSON.stringify(value));
}

export const getLocalProvider = () => {
    return JSON.parse(localStorage.getItem('pusproject.provider'));
}

export const removeLocalProvider = () => {
    localStorage.removeItem('pusproject.provider');
}

export const clearLocal = () => {
    localStorage.clear();
}