import { Route, Routes } from 'react-router-dom';
import { ProtectedAuth } from './components';
import { AuthContextProvider } from './contexts/auth';
import { RegisterContextProvider } from './contexts/auth/RegisterContext';
import { ServiceContextProvider } from './contexts/service';
import { ServiceAddContextProvider } from './contexts/service/form/ServiceAddContext';
import { OverviewPage, RegisterPage, ServiceAddPage, ServicePage } from './pages';

function App() {
  return (
    <Routes>
      <Route path="/" element={<AuthContextProvider />} />
      <Route path="/register" element={
        <ProtectedAuth isAuthentication>
          <RegisterContextProvider>
            <RegisterPage />
          </RegisterContextProvider>
        </ProtectedAuth>
      } />
      {/* Overview */}
      <Route path="/overview" element={
        <OverviewPage />
      } />
      {/* Service */}
      <Route path="/service" element={
        <ServiceContextProvider>
          <ServicePage />
        </ServiceContextProvider>
      } />
      <Route path="/service/add" element={
        <ServiceAddContextProvider>
          <ServiceAddPage />
        </ServiceAddContextProvider>
      } />
    </Routes>
  );
}

export default App;
