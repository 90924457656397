import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteServiceRequest, showServiceRequest } from "../../requests";
import { getLocalToken } from "../../utils";

const ServiceContext = createContext();

export const ServiceContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const token = getLocalToken();
    const [service, setService] = useState([]);

    const getService = async () => {
        await showServiceRequest(token.token).then((res) => {
            setService(res.data.service.data);
        });
    }

    const onServiceDelete = async (id) => {
        await deleteServiceRequest({ token: token.token, body: { id: id } }).then((res) => {
            getService();
        });
    }

    useEffect(() => {
        getService();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ServiceContext.Provider value={{ navigation, service, onServiceDelete }}>
            {children}
        </ServiceContext.Provider>
    );
}

export const UseServiceContext = () => {
    return useContext(ServiceContext);
}