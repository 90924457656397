import { Button, Card, Content, HorizontalDevider, InputText } from "../../components";
import { UseRegisterContext } from "../../contexts/auth/RegisterContext";

const RegisterPage = () => {
    const { loader, controller, onSetDomain, onSetController, onSaved } = UseRegisterContext();

    return (
        <Content sidebar={false}>
            <div className="flex justify-center">
                <Card className="px-5">
                    <h1 className="text-xl font-bold">Daftar Provider</h1>
                    <span>Lengkapi informasi yang dibutuhkan untuk verifikasi</span>
                    <div className="my-4">
                        <HorizontalDevider />
                    </div>
                    <div className="text-start mt-5">
                        <div className="leading-3 mb-2">
                            <h6 className="font-semibold">Nama Provider</h6>
                            <small>Nama penyedia, atau nama perusahaan yang akan Anda bangun</small>
                        </div>
                        <InputText placeholder="Tulis disini..." onChange={(value) => onSetController('name', value)} />
                    </div>
                    <div className="text-start mt-2">
                        <div className="leading-3 mb-2">
                            <h6 className="font-semibold">Alamat Provider</h6>
                            <small>Bagikan dan buka halaman provider Anda dengan alamat ini</small>
                        </div>
                        <InputText value={`https://pusproject.com/${onSetDomain(controller.name ?? '')}`} />
                    </div>
                    <div className="my-4">
                        <HorizontalDevider />
                    </div>
                    <div className="leading-3">
                        <h6 className="font-semibold">Kontak</h6>
                        <small>Kami akan mengirimkan informasi verifikasi ke kontak ini</small>
                        <div className="text-start mt-3">
                            <InputText readOnly={true} value={controller.kontak ?? ''} placeholder="..." />
                        </div>
                    </div>
                    <div className="mt-5">
                        <Button className="bg-violet-500 text-white py-[0.6rem] justify-center" loader={loader.register} onClick={() => onSaved()}>
                            Daftar Provider
                        </Button>
                    </div>
                </Card>
            </div>
        </Content>
    );
}

export default RegisterPage;