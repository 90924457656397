import { Navigate } from "react-router-dom";
import { getLocalProvider } from "../../../utils/storage/LocalStorage";

const ProtectedAuth = ({ children }) => {
    const provider = getLocalProvider();

    if (provider) {
        return <Navigate to={'/overview'} />
    }

    return children;
}

export default ProtectedAuth;