import { useEffect, useRef, useState } from "react";
import { Button } from "../../atoms";

const MenuTableActionModal = (props) => {
    const action = [
        {
            status: props.onUpdate ? true : false,
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4"></path>
                <path d="M13.5 6.5l4 4"></path>
            </svg>,
            title: 'Edit',
            action: () => props.onUpdate && props.onUpdate(),
        },
        {
            status: props.onDelete ? true : false,
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
            </svg>,
            title: 'Hapus',
            action: () => props.onDelete && props.onDelete(),
        },
    ];
    const ref = useRef();
    const [element, setElement] = useState();

    const renderElement = () => {
        return (
            <div className={`absolute top-9 right-0 bg-white border rounded-lg z-10 w-48`}>
                <ul>
                    {action.map((item, index) => {
                        return item.status ? <li key={index} className="whitespace-pre font-medium px-3 py-2 hover:bg-slate-50 cursor-pointer" onClick={() => { setElement(undefined); item.action(); }}>
                            <div className="flex gap-2">
                                {item.icon}
                                <span>{item.title}</span>
                            </div>
                        </li> : undefined;
                    })}
                </ul>
            </div>
        );
    }

    const handleClickOutside = (event) => {
        if (!ref?.current?.contains(event.target)) {
            setElement(undefined);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div className="flex gap-3">
            <div className="relative" ref={ref}>
                <Button className="border gap-4" onClick={() => setElement(element === undefined ? renderElement() : undefined)}>
                    <span>Atur</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M6 9l6 6l6 -6"></path>
                    </svg>
                </Button>
                {element}
            </div>
        </div>
    );
}

export default MenuTableActionModal;