import { createContext, useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import { storeServiceRequest } from "../../../requests";
import { getLocalToken } from "../../../utils";

const ServiceAddContext = createContext();

export const ServiceAddContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const token = getLocalToken();
    const [controller, setController] = useState({});
    const [validate, setValidate] = useState({});
    const [category, setCategory] = useState([]);
    const photo = [
        {
            title: 'Foto Utama',
        },
        {
            title: 'Foto 1',
        },
        {
            title: 'Foto 2',
        },
        {
            title: 'Foto 3',
        },
        {
            title: 'Foto 4',
        }
    ];

    const [tier, setTier] = useState([
        {
            key_item: 0,
            title: '',
            price: '',
            type: '',
            value: '',
        }
    ]);

    const [include, setInclude] = useState([]);

    const onSetController = (field, value) => {
        setController({ ...controller, [field]: value });
    }

    const onSetCategory = (value) => {
        let categoryIndex = category.findIndex((x) => x === value);
        categoryIndex < 0 && setCategory([...category, value]);
    }

    const onDeleteCategory = (index) => {
        category.splice(index, 1);
        setCategory([...category]);
    }

    const onSetTier = (index, field, value) => {
        tier[index][field] = value;
        setTier([...tier]);
    }

    const onAddTier = () => {
        let tierData = {
            key_item: tier[tier.length - 1].key_item + 1,
            title: '',
            price: '',
            type: '',
            value: '',
        };

        tier.length < 3 && setTier([...tier, tierData]);
        tier.length < 3 && addTierOnInclude(tierData);
    };

    const addTierOnInclude = (tier) => {
        include.forEach((item, index) => {
            item.tier = [...item.tier, tier];
        });

        setInclude([...include]);
    }

    const onDeleteTier = (index) => {
        // delete tier in include
        deleteTierOnInclude(tier[index].key_item);
        tier.splice(index, 1);
        setTier([...tier]);
    }

    const deleteTierOnInclude = (tierKey) => {
        include.forEach((item, index) => {
            let tierIndex = item.tier.findIndex((x) => x.key_item === tierKey);
            item.tier.splice(tierIndex, 1);
        });

        setInclude([...include]);
    }

    const onSetInclude = (value) => {
        value && setInclude([
            ...include,
            {
                title: value,
                tier: tier,
            }
        ]);
    }

    const onCheckInclude = (index, tierIndex, value) => {
        let includeTier = [...include[index].tier];

        includeTier[tierIndex] = {
            ...includeTier[tierIndex],
            ...value,
        };

        include[index].tier = includeTier;
        setInclude([...include]);
    }

    const onDeleteInclude = (index) => {
        include.splice(index, 1);
        setInclude([...include]);
    }

    const validation = () => {
        let field = ['title', 'detail', 'file'];
        let validation = {};
        // Validation for data service
        field.forEach((item, index) => {
            (!controller[item] || controller[item] === '') && (validation[item] = 'Harus Diisi...');
        });

        // Validation for pricing
        field = ['title', 'price'];
        tier.forEach((item, index) => {
            Object.keys(item).forEach((itemChild, indexChild) => {
                if (field.includes(itemChild)) {
                    item[itemChild] === '' && (validation[`${itemChild}${index}`] = 'Harus Diisi...');
                }
            });
        });

        // Include
        include.length < 3 && (validation['include'] = 'Berikan minimal 3 keuntungan');
        Object.keys(validation).length > 0 && setValidate(validation);
        return Object.keys(validation).length === 0 && true;
    }

    const onSaved = async () => {
        if (validation()) {
            delete controller.drafTier;
            controller.category = category;
            controller.tier = tier;
            controller.include = include;
            await storeServiceRequest({ token: token.token, body: controller }).then((res) => {
                navigation('/service');
            });
        }
    }

    return (
        <ServiceAddContext.Provider value={{ navigation, controller, validate, category, onSetCategory, onDeleteCategory, onSetController, photo, tier, onSetTier, onAddTier, onDeleteTier, include, onSetInclude, onCheckInclude, onDeleteInclude, onSaved }}>
            {children}
        </ServiceAddContext.Provider>
    );
}

export const UseServiceAddContext = () => {
    return useContext(ServiceAddContext);
}