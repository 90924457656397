import { Button, Card, CategoryServiceModal, Content, FileDragDrop, HorizontalDevider, InputArea, InputChecked, InputNumber, InputText } from "../../../components";
import { UseServiceAddContext } from "../../../contexts/service/form/ServiceAddContext";

const ServiceAddPage = () => {
    const { navigation, controller, validate, category, onSetCategory, onDeleteCategory, onSetController, photo, tier, onSetTier, onAddTier, onDeleteTier, include, onSetInclude, onCheckInclude, onDeleteInclude, onSaved } = UseServiceAddContext();

    return (
        <Content sidebar={false}>
            <Card className="p-8">
                <div>
                    <h1 className="text-xl font-bold">Tambah Layanan</h1>
                </div>
                <div className="mt-4">
                    <div className="text-start mt-5 md:grid md:grid-cols-4 md:gap-4">
                        <div className="leading-3 mb-2">
                            <h6 className="font-semibold">Judul Layanan</h6>
                            <small>Berikan judul yang sering di cari onleh customer, itu akan meningkatkan kualitas produk dan pencarian customer</small>
                        </div>
                        <div className="col-span-3">
                            <InputText error={validate.title} placeholder="Tulis disini..." onChange={(value) => onSetController('title', value)} />
                        </div>
                    </div>
                    <div className="my-5">
                        <div className="text-start mt-5 md:grid md:grid-cols-4 md:gap-4">
                            <div className="leading-3 mb-2">
                                <h6 className="font-semibold">Foto Layanan</h6>
                                <small>Foto berupa (PNG, JPEG, JPG), ukuran foto max 5Mb, dimensi foto (1024x1024)</small>
                            </div>
                            <div className="col-span-3">
                                <div className="flex flex-wrap gap-2">
                                    {photo.map((item, index) => {
                                        return (
                                            <FileDragDrop key={index} preview className="w-32 h-32" placeholder={item.title} onChange={(item) => onSetController('file', { ...controller.file, [`file${index}`]: item })} />
                                        );
                                    })}
                                </div>
                                {validate.file && <small className="text-red-700">{validate.file}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="text-start mt-5 md:grid md:grid-cols-4 md:gap-4">
                        <div className="leading-3 mb-2">
                            <h6 className="font-semibold">Detail Layanan</h6>
                            <small>Beritahu customer tentang layanan, Detail layanan akan lebih meyakinkan para customer untuk bisa membeli layanan</small>
                        </div>
                        <div className="col-span-3">
                            <InputArea error={validate.detail} placeholder="Tulis disini..." onChange={(value) => onSetController('detail', value)} />
                        </div>
                    </div>
                </div>
                <div className="my-5">
                    <HorizontalDevider />
                </div>
                <div>
                    <div className="text-start mt-5 md:grid md:grid-cols-4 md:gap-4">
                        <div className="leading-3 mb-2">
                            <h6 className="font-semibold">Kategori</h6>
                            <small>Berikan kategori sesuai dengan layanan</small>
                        </div>
                        <div className="col-span-3">
                            {category.length > 0 && (
                                <div className="flex flex-wrap gap-2 mb-3">
                                    {category.map((item, index) => {
                                        return (
                                            <div key={index} className="rounded-full border border-slate-200 px-3 py-2 leading-4 flex gap-2">
                                                {item}
                                                <span className="cursor-pointer" onClick={() => onDeleteCategory(index)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-violet-400" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                                        <path d="M9 12l6 0"></path>
                                                    </svg>
                                                </span>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            <CategoryServiceModal callback={(value) => onSetCategory(value)} />
                            <div>
                                <small>Min 1 Kategori</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-5">
                    <HorizontalDevider />
                </div>
                <div>
                    <h4 className="font-semibold">Harga Layanan</h4>
                    <div className="mt-4">
                        <div className="text-start md:grid md:grid-cols-4 md:gap-4">
                            <div>
                                {tier.map((item, index) => {
                                    return (
                                        <div className="border p-3 rounded-md mt-2 relative" key={index}>
                                            <h6 className="font-semibold">Judul</h6>
                                            <InputText error={validate[`title${index}`]} value={item.title} placeholder="Tulis disini..." onChange={(value) => onSetTier(index, 'title', value)} />
                                            <h6 className="font-semibold mt-2">Harga</h6>
                                            <InputNumber error={validate[`price${index}`]} value={item.price} placeholder="Tulis disini..." onChange={(value) => onSetTier(index, 'price', value)} />
                                            {/* Button Delete */}
                                            {index > 0 && (
                                                <div className="absolute -top-2 -right-2 bg-white rounded-full p-1 cursor-pointer" onClick={() => onDeleteTier(index)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-violet-400" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                                        <path d="M9 12l6 0"></path>
                                                    </svg>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                                {tier.length < 3 && (
                                    <Button className="border mt-2" onClick={() => onAddTier()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M12 5l0 14"></path>
                                            <path d="M5 12l14 0"></path>
                                        </svg>
                                        Tambah
                                    </Button>
                                )}
                            </div>
                            <div className="col-span-3">
                                <h6 className="font-semibold mt-3 md:mt-0">Keuntungan Yang Diberikan</h6>
                                <div className="my-3 flex gap-3">
                                    <InputText error={validate.drafTier || validate.include} value={controller.drafTier ?? ''} placeholder="Tulis disini..." onChange={(value) => onSetController('drafTier', value)} />
                                    <div>
                                        <Button className="border bg-violet-500 text-white py-[0.43rem]" onClick={() => { onSetInclude(controller.drafTier); onSetController('drafTier', '') }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M12 5l0 14"></path>
                                                <path d="M5 12l14 0"></path>
                                            </svg>
                                            Tambah
                                        </Button>
                                    </div>
                                </div>
                                <div className="border rounded-md overflow-y-hidden overflow-x-auto mt-2 p-4">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-sm font-bold text-slate-600 w-full">Keuntungan</th>
                                                {tier.map((item, index) => {
                                                    return (
                                                        <th key={index} scope="col" className="px-6 py-3 text-left text-sm font-bold text-slate-600 whitespace-nowrap">{item.title === '' ? 'Belum ada Judul' : item.title}</th>
                                                    );
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {include.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="px-6 py-3 text-sm font-medium text-gray-800">
                                                            <div className="flex gap-3">
                                                                <div className="cursor-pointer" onClick={() => onDeleteInclude(index)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-violet-400" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                                                        <path d="M9 12l6 0"></path>
                                                                    </svg>
                                                                </div>
                                                                <span className="font-semibold mt-[2px] text-slate-500">{item.title}</span>
                                                            </div>
                                                        </td>
                                                        {item?.tier?.map((itemChild, indexChild) => {
                                                            return (
                                                                <td key={indexChild} className="px-6 py-3 whitespace-nowrap text-sm text-gray-800">
                                                                    <div className="flex justify-center">
                                                                        <InputChecked checked={itemChild.value ?? false} onChange={(value) => onCheckInclude(index, indexChild, { type: 'check', value: value })} />
                                                                    </div>
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    {include.length === 0 && (<div className="text-slate-400 font-bold px-6 py-3 border-t">Keuntungan Belum Ditambahkan</div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap justify-end gap-3 mt-5">
                    <Button className="border border-violet-500 py-[0.4rem]" onClick={() => navigation(-1)}>Batal</Button>
                    {/* <Button className="border border-violet-500 py-[0.4rem]">Simpan {`&`} Tambah Baru</Button> */}
                    <Button className="bg-violet-500 text-white py-[0.4rem]" onClick={() => onSaved()}>Simpan</Button>
                </div>
            </Card>
        </Content>
    );
}

export default ServiceAddPage;