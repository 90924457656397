import { Button, Content, InputSearch, MenuTableActionModal, Table, TableBody, TableHead, TableTd, TableTh, TableTr } from "../../components";
import { UseServiceContext } from "../../contexts/service";
import { dateFormatter, elipseText } from "../../utils";

const ServicePage = () => {
    const { navigation, service, onServiceDelete } = UseServiceContext();

    return (
        <Content>
            <div className="flex justify-between items-center">
                <div>
                    <h1 className="text-xl font-bold">Layanan</h1>
                    <span>Daftar Layanan</span>
                </div>
                <div>
                    <Button className="bg-violet-500 text-white py-[0.5rem]" onClick={() => navigation('/service/add')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M12 5l0 14"></path>
                            <path d="M5 12l14 0"></path>
                        </svg>
                        <span>Tambah Layanan</span>
                    </Button>
                </div>
            </div>
            <div className="mt-3">
                <div className="bg-white rounded-lg shadow-sm border pb-4">
                    <div className="py-3 px-4">
                        <div className="flex">
                            <InputSearch className="shadow-none" placeholder="Cari..." />
                        </div>
                    </div>
                    <div>
                        <Table>
                            <TableHead>
                                <TableTr>
                                    <TableTh className="w-16 text-center">No</TableTh>
                                    <TableTh>Info Layanan</TableTh>
                                    <TableTh>Detail</TableTh>
                                    <TableTh>Status</TableTh>
                                    <TableTh></TableTh>
                                </TableTr>
                            </TableHead>
                            <TableBody>
                                {service.map((item, index) => {
                                    return (
                                        <TableTr key={index}>
                                            <TableTd className="text-center">
                                                <span className="font-semibold">{(index + 1)}.</span>
                                            </TableTd>
                                            <TableTd>
                                                <div className="flex gap-3">
                                                    <div>
                                                        <img src={item.service_file_model[0].path} alt="ImageService" className="w-16 max-w-[4rem] min-w-[4rem] h-16 rounded-lg border-2 border-slate-300" />
                                                    </div>
                                                    <div>
                                                        <span className="text-md font-bold">{elipseText(item.title, 30)}</span><br />
                                                        <span className="whitespace-pre">Dibuat: {dateFormatter(item.created_at)}</span>
                                                    </div>
                                                </div>
                                            </TableTd>
                                            <TableTd>{elipseText(item.detail, 90)}</TableTd>
                                            <TableTd>
                                                <div className="rounded-full px-2 py-1 text-xs bg-green-100 text-green-700 font-bold inline-block whitespace-pre">{item.status}</div>
                                            </TableTd>
                                            <TableTd>
                                                <MenuTableActionModal onDelete={() => onServiceDelete(item.id)} />
                                            </TableTd>
                                        </TableTr>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default ServicePage;