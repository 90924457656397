import axios from "axios";
import { API_SERVICE_CATEGORY } from "../../configs/api";

export const showServiceCategoryRequest = async ({ token, key = '' }) => {
    try {
        const response = await axios.get(`${API_SERVICE_CATEGORY}?filter[key]=${key}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        return response.data;
    } catch (error) {
        return false;
    }
}