import { createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../../requests";
import { setLocalUser, setLocalToken, setLocalProvider, removeLocalProvider } from "../../utils/storage/LocalStorage";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const navigation = useNavigate();

    const authenthication = () => {
        let parUrl = window.location.search;
        let urlParam = new URLSearchParams(parUrl);
        let parameter = urlParam.get('token');
        removeLocalProvider();
        setLocalToken({ token: parameter });
        setTimeout(() => {
            getAuthentication(parameter);
        }, 300);
    }

    const getAuthentication = async (token) => {
        await loginRequest(token).then((res) => {
            if (res) {
                res.data.user && setLocalUser({ authFromServer: { data: { user: res.data.user } } });
                res.data.provider && setLocalProvider({ authProvider: { provider: res.data.provider } });
            }
            setTimeout(() => {
                checkAuth(res.data.provider ?? false);
            }, 300);
        });
    }

    const checkAuth = (provider) => {
        if (provider) {
            navigation('/overview');
        } else {
            navigation('/register');
        }
    }

    useEffect(() => {
        authenthication();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthContext.Provider value={{ navigation }}>
            {children}
        </AuthContext.Provider>
    );
}

export const UseAuthContext = () => {
    return useContext(AuthContext);
}