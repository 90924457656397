import { useNavigate } from "react-router-dom";

const Sidebar = (props) => {
    const navigation = useNavigate();

    return (
        <aside className="relative w-[14.5rem] w-min-[14.5rem] w-max-[14.5rem] bg-white">
            <div className="px-4 py-3 border-y cursor-pointer">
                <div className="flex gap-3 items-center justify-start text-sm text-slate-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M15 6l-6 6l6 6"></path>
                    </svg>
                    <span className="font-bold">Sembunyikan Menu</span>
                </div>
            </div>
            <div className="px-4 py-2 cursor-pointer mt-4 hover:bg-slate-100" onClick={() => navigation('/overview')}>
                <div className="flex gap-3 items-center text-sm text-violet-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M19 13v-1h2l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h2.5"></path>
                        <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2"></path>
                        <path d="M13 22l3 -3l2 2l4 -4"></path>
                        <path d="M19 17h3v3"></path>
                    </svg>
                    <span className="font-bold">Overview</span>
                </div>
            </div>
            <div className="px-4 py-2 cursor-pointer hover:bg-slate-100" onClick={() => navigation('/material')}>
                <div className="flex gap-3 items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-800" width="22" height="22" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M8 9h8"></path>
                        <path d="M8 13h6"></path>
                        <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z"></path>
                    </svg>
                    <span className="font-bold">Chat</span>
                </div>
            </div>
            <div className="px-4 py-2 cursor-pointer hover:bg-slate-100" onClick={() => navigation('/service')}>
                <div className="flex gap-3 items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-800" width="22" height="22" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"></path>
                        <path d="M9 8a3 3 0 0 0 6 0"></path>
                    </svg>
                    <span className="font-bold">Layanan</span>
                </div>
            </div>
        </aside>
    );
}

export default Sidebar;