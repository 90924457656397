const REACT_APP_API_URL = `${process.env.REACT_APP_ENV}/api/provider/v1/`;

// Auth
export const API_LOGIN = `${REACT_APP_API_URL}login`;
export const API_REGISTER = `${REACT_APP_API_URL}register`;
// Service
export const API_SERVICE_SHOW = `${REACT_APP_API_URL}service`;
export const API_SERVICE_STORE = `${REACT_APP_API_URL}service/store`;
export const API_SERVICE_DELETE = `${REACT_APP_API_URL}service/delete`;
// Service Category
export const API_SERVICE_CATEGORY = `${REACT_APP_API_URL}service/category`;