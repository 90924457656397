import axios from "axios";
import { API_LOGIN, API_REGISTER } from "../../configs/api";

export const loginRequest = async (token) => {
    try {
        const response = await axios.get(API_LOGIN, {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        return response.data;
    } catch (error) {
        return false;
    }
}

export const registerRequest = async ({ token, body }) => {
    try {
        const response = await axios.post(API_REGISTER, body, {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        return response.data;
    } catch (error) {
        return false;
    }
}