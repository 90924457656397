import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { registerRequest } from "../../requests";
import { getLocalUser, getLocalToken, setLocalProvider } from "../../utils/storage/LocalStorage";

const RegisterContext = createContext();

export const RegisterContextProvider = ({ children }) => {
    const token = getLocalToken();
    const user = getLocalUser();
    const navigation = useNavigate();
    const [controller, setController] = useState({});
    const [loader, setLoader] = useState({});

    const onSetController = (field, value) => {
        setController({ ...controller, [field]: value });
    }

    const onSetLoader = (field, value) => {
        setLoader({ ...loader, [field]: value });
    }

    const onSetDomain = (value) => {
        const regexPatteren = /[^a-zA-Z0-9]/g;
        let valid = value.replace(regexPatteren, '');
        valid = valid.toLowerCase();
        controller.domain = valid;
        return valid;
    }

    const onSaved = async () => {
        onSetLoader('register', true);
        await registerRequest({ token: token.token, body: { ...controller } }).then((res) => {
            onSetLoader('register', false);
            if (res) {
                setLocalProvider({ authProvider: res.data });
                setTimeout(() => {
                    navigation('/overview');
                }, 300);
            }
        });
    }

    useEffect(() => {
        onSetController('kontak', user.authFromServer.data.user.email);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <RegisterContext.Provider value={{ loader, controller, onSetDomain, onSetController, onSaved }}>
            {children}
        </RegisterContext.Provider>
    );
}

export const UseRegisterContext = () => {
    return useContext(RegisterContext);
}