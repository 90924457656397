import { useNavigate } from "react-router-dom";
import { AssetLogo, AvAvatar1 } from "../../../assets";
import { getLocalProvider, getLocalUser } from "../../../utils/storage/LocalStorage";
import { VerticalDevider } from "../../atoms";

const Navbar = (props) => {
    const user = getLocalUser();
    const provider = getLocalProvider();
    const navigation = useNavigate();

    const renderContentNavAuth = () => {
        let content = user && provider ? renderProfile(user, provider) : (<></>);
        return content;
    }

    const renderProfile = (user, provider) => {
        return (
            <>
                <div>
                    <span className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-slate-700" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
                            <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                            <path d="M21 6.727a11.05 11.05 0 0 0 -2.794 -3.727"></path>
                            <path d="M3 6.727a11.05 11.05 0 0 1 2.792 -3.727"></path>
                        </svg>
                    </span>
                </div>
                <div className="mx-3">
                    <VerticalDevider />
                </div>
                <div className="flex items-center gap-2 hover:bg-slate-100 rounded-md py-1 px-2 cursor-pointer" onClick={() => { }} onMouseOver={() => { }} onMouseLeave={() => { }}>
                    {provider.authProvider.provider.photo_full ? (<img src={provider.authProvider.provider.photo_full} alt="FotoProfile" className="w-[27.19px] h-[27.19px] rounded-full" />) : (<img src={AvAvatar1} alt="FotoProfile" className="w-[27.19px] h-[27.19px] rounded-full" />)}
                    <span className="font-semibold">{provider.authProvider.provider.name ? provider.authProvider.provider.name.split(' ')[0] : 'Anonim'}</span>
                </div>
                <div className="flex items-center gap-2 hover:bg-slate-100 rounded-md py-1 px-2 cursor-pointer" onClick={() => { }} onMouseOver={() => { }} onMouseLeave={() => { }}>
                    {user.authFromServer.data.user.photo ? (<img src={user.authFromServer.data.user.photo} alt="FotoProfile" className="w-[27.19px] h-[27.19px] rounded-full" />) : (<img src={AvAvatar1} alt="FotoProfile" className="w-[27.19px] h-[27.19px] rounded-full" />)}
                    <span className="font-semibold">{user.authFromServer.data.user.name ? user.authFromServer.data.user.name.split(' ')[0] : 'Anonim'}</span>
                </div>
            </>
        );
    }

    return (
        <nav className="bg-white py-3 px-7 shadow-sm z-20 flex justify-between items-center">
            <div className="cursor-pointer" onClick={() => navigation('/overview')}>
                <AssetLogo width="149" height="29" />
            </div>
            <div className="flex items-center gap-2">
                {renderContentNavAuth()}
            </div>
        </nav>
    );
}

export default Navbar;