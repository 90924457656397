import axios from "axios";
import { API_SERVICE_DELETE, API_SERVICE_SHOW, API_SERVICE_STORE } from "../../configs/api";

export const showServiceRequest = async (token) => {
    try {
        const response = await axios.get(API_SERVICE_SHOW, {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        return response.data;
    } catch (error) {
        return false;
    }
}

export const storeServiceRequest = async ({ token, body }) => {
    try {
        const response = await axios.post(API_SERVICE_STORE, body, {
            headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return false;
    }
}

export const deleteServiceRequest = async ({ token, body }) => {
    try {
        const response = await axios.post(API_SERVICE_DELETE, body, {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
        return response.data;
    } catch (error) {
        return false;
    }
}